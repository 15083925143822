import { createMachine, state as final, state, transition } from "robot3";

export const appStateMachine = createMachine("hidden", {
    inactive: state(transition("ACTIVATE", "hidden")),
    hidden: state(
        transition("DEACTIVATE", "inactive"),
        transition("MINIMISE", "minimised"),
        transition("SHOW", "visible"),
        transition("FINISH", "finished")
    ),
    minimised: state(transition("HIDE", "hidden"), transition("SHOW", "visible")),
    visible: state(transition("DEACTIVATE", "inactive"), transition("MINIMISE", "minimised"), transition("HIDE", "hidden")),
    finished: final(),
});
