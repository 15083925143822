import "construct-style-sheets-polyfill";

import { App, appConfig, appElements } from "@app/app";
import * as Sentry from "@sentry/react";
import { observe } from "@twind/core";
import { createRoot } from "react-dom/client";

if (process.env.NODE_ENV === "production") {
    /**
     * @note - Sentry has major impact in build size:
     * ~215kB (67kB gzip)
     * @todo - 1. upgrade ws2 sentry version to same as IBE
     *         2. implement shared Sentry library if possible
     */
    Sentry.init({
        dsn: appConfig.sentryDsn,
        integrations: [new Sentry.Replay()],
        tracesSampleRate: 0.25, // 25% of events will be logged (to be fine tuned)
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

/**
 * Main
 */
export let shadowRoot: ShadowRoot;

customElements.define(
    "hc-ibe",
    class extends HTMLElement {
        public connectedCallback() {
            const { sheet, tw } = appElements;

            shadowRoot = this.attachShadow({ mode: "open" });

            // shadowRoot.childNodes.forEach((childNode) => {
            //     this.appendChild(childNode);
            // });

            // shadow DOM as react root
            const root = createRoot(shadowRoot);

            // render react element inside shadow DOM
            root.render(
                <div>
                    <App />
                </div>
            );

            // link sheet target to shadow dom root
            shadowRoot.adoptedStyleSheets = [sheet.target];

            // finally, observe using tw function
            observe(tw, shadowRoot);
        }
    }
);
