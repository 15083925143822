import React, { useCallback, useEffect, useState } from "react";

import { ActiveBookingContext, IActiveBookingContext } from "./ActiveBookingContext";

export interface IActiveBookingContextProviderProps {
    initialActiveBookingId?: string;
    children?: React.ReactNode | React.ReactNode[];
}

export const ActiveBookingContextProvider = ({ initialActiveBookingId, children }: IActiveBookingContextProviderProps) => {
    const [bookingIds, setBookingIds] = useState<string[]>([]);
    const [activeBookingId, setActiveBookingId] = useState<string>();

    useEffect(() => {
        if ((!activeBookingId || activeBookingId !== bookingIds[0]) && bookingIds.length > 0) {
            const initialActiveBooking = initialActiveBookingId || bookingIds[0];

            setActiveBookingId(initialActiveBooking);
        }
    }, [bookingIds]);

    // Reset the active booking to the first item in the list
    useEffect(() => {
        if (activeBookingId === undefined) {
            setActiveBookingId(bookingIds[0]);
        }
    }, [activeBookingId]);

    const activeBookingIndex = bookingIds.findIndex((id) => id === activeBookingId);

    const setNext = () => {
        const currentIndex = activeBookingIndex;
        const hasNext = currentIndex !== -1 && bookingIds.length - 1 > currentIndex;
        const nextBookingId = hasNext ? bookingIds[currentIndex + 1] : undefined;

        setActiveBookingId(nextBookingId);

        return nextBookingId;
    };

    const setPrevious = () => {
        const currentIndex = activeBookingIndex;
        const hasPrevious = currentIndex !== -1 && currentIndex - 1 > -1;
        const previousBookingId = hasPrevious ? bookingIds[currentIndex - 1] : undefined;

        setActiveBookingId(previousBookingId);

        return previousBookingId;
    };

    const setSpecific = (id: string) => {
        const specificBookingId = bookingIds.find((b) => b === id);
        const validBookingId = specificBookingId || activeBookingId;

        setActiveBookingId(validBookingId);
    };

    const api: IActiveBookingContext = {
        activeBookingId,
        activeBookingIndex,
        setNext: useCallback(setNext, [setNext]),
        setPrevious: useCallback(setPrevious, [setPrevious]),
        setBookingIds: useCallback(setBookingIds, [setBookingIds]),
        setSpecific: useCallback(setSpecific, [setSpecific]),
    };

    return <ActiveBookingContext.Provider value={api}>{children}</ActiveBookingContext.Provider>;
};
