import { utilsQuery } from "@app/common";

const define = utilsQuery.defineQueryKeyCreator("availability");

export const QueryKeys = {
    AvailabilityItems: define("availability-items"),
    AvailabilityItem: define("availability-item"),
    AvailabilityCheck: define("availability-check"),
    CalendarAvailabilityItems: define("calendar-availability-items"),
    CalendarWithRates: define("calendar-wtih-rates"),
} as const;
