import { axios } from "@app/app";

import { IAvailability, IAvailabilityCheck, ICalendarAvailability } from "./types";

export const getAvailability = async (propertyId: string, searchQuery: string): Promise<IAvailability[]> => {
    const response = await axios.get<any>(`/properties/${propertyId}/availability/get?${searchQuery}`);

    return response.data;
};

export const checkAvailability = async (propertyId: string, searchQuery: string): Promise<IAvailabilityCheck> => {
    const response = await axios.get<any>(`/properties/${propertyId}/availability/check?${searchQuery}`);

    return response.data;
};

export const getCalendarAvailability = async (
    propertyId: string,
    fromDate: string,
    toDate: string,
    withRates?: boolean
): Promise<ICalendarAvailability> => {
    const includeRates = withRates ? "&with_rates=1" : "";
    const response = await axios.get<ICalendarAvailability>(
        `/properties/${propertyId}/calendar?start=${fromDate}&end=${toDate}${includeRates}`
    );

    return response.data;
};
