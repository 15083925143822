import i18n from "i18next";

i18n.init({
    partialBundledLanguages: true,
    ns: [],
    resources: {},
    fallbackLng: "en",
    saveMissing: true,
    parseMissingKeyHandler: () => null,
    missingKeyNoValueFallbackToKey: true,
});

export default i18n;
