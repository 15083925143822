import { cssom, injectGlobal as twindInjectGlobal, twind } from "@twind/core";
import { keyframes as twindKeyframes } from "twind/css";

import twindConfig from "../../../twind.config";
import { config } from "../config";

const win = window as any;

const containerId = "hc-ibe-root";

// export let container = document.getElementById(containerId);

// export let shadowContainer: HTMLDivElement;

// if (!container) {
export const container = document.createElement("div");
container.id = containerId;
container.className = "w-full h-full";
container.style["WebkitOverflowScrolling" as any] = "auto";
container.style.overflow = "auto";
container.style.zIndex = "2147483646";
container.style.position = "relative";
container.style.fontFamily = '"DM Sans", system-ui, sans-serif';

document.body.appendChild(container);

const ibe = document.createElement("hc-ibe");
const stripePaymentMethodsContainer = document.createElement("div");
stripePaymentMethodsContainer.setAttribute("slot", "stripe-payment-methods");

const stripeExpressCheckoutContainer = document.createElement("div");
stripeExpressCheckoutContainer.setAttribute("slot", "stripe-express-checkout");

ibe.appendChild(stripePaymentMethodsContainer);
ibe.appendChild(stripeExpressCheckoutContainer);

container.appendChild(ibe);
// }

if (!container && config.appEnv !== "test") {
    throw Error(`Could not find container element ${containerId}`);
}

// attach shadow DOM to container
// export const shadowRoot = shadowContainer?.attachShadow({ mode: "open" });

/**
 * Handle Tailwind CSS for shadow DOM
 * @info - https://gourav.io/blog/tailwind-in-shadow-dom
 */

// // Create separate CSSStyleSheet
const plainSheet = new CSSStyleSheet();

// Temp solution to inject custom styles to hide scrollbar but to keep scrolling functionality
// Used for slideshow
// @todo - find better implementation

if (process.env.NODE_ENV !== "test") {
    // plainSheet.replaceSync(`
    // @import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&display=swap');
    // .no-scrollbar::-webkit-scrollbar {
    //     display: none;
    // }
    // .no-scrollbar {
    //     -ms-overflow-style: none; /* IE and Edge */
    //     scrollbar-width: none; /* Firefox */
    // }`);
}

win.plainSheet = plainSheet;

export const sheet = cssom(plainSheet);

// Use sheet and config to create an twind instance. `tw` will
// append the right CSS to our custom stylesheet.
export const tw = twind(twindConfig, sheet);

export const injectGlobal = twindInjectGlobal.bind(tw);
export const keyframes = twindKeyframes.bind(tw);
