import { appHooks, appUtils, storageManager } from "@app/app";
import { AvailabilityRequestType } from "@app/availability";
import { bookingHooks } from "@app/booking";
import { PaymentIntentCaptureMethod, PaymentTypes, paymentUtils } from "@app/payment";
import { UtilsIdentifier, UtilsUrl } from "@hotelchamp/common";
import React, { useCallback, useEffect, useState } from "react";

import { CheckoutSessionProviderMode } from "../Constants/CheckoutSessionProviderMode";
import { CheckoutSessionStatus } from "../Constants/CheckoutSessionStatus";
import { StorageKeys } from "../Constants/StorageKeys";
import { CheckoutSessionContext, ICheckoutSessionContext } from "../context/CheckoutSessionContext";
import { useUpsertCheckoutSession } from "../hooks";
import { ICheckoutSessionState } from "../types";

export type TCheckoutSessionProviderChildrenRenderProp = (context: ICheckoutSessionContext) => React.ReactNode | React.ReactNode[];

export interface ICheckoutSessionProviderProps {
    children?: React.ReactNode | React.ReactNode[] | TCheckoutSessionProviderChildrenRenderProp;
    mode?: CheckoutSessionProviderMode;
}

const DEFAULT_CAPTURE_METHOD = PaymentIntentCaptureMethod.AutomaticAsync;

const createEmptyState = (): ICheckoutSessionState => ({
    session_id: UtilsIdentifier.uuid(),
    status: CheckoutSessionStatus.InProgress,
    capture_method: DEFAULT_CAPTURE_METHOD,
    payment_type: PaymentTypes.Direct,
});

/**
 * @note - When debugging session id's and id changes more often, possible reasons:
 *  - The state is initialized with empty state and a new session id (before restored from localStorage)
 *  - The state will asynchronousely restored from localStorage, if present, which could update initial session id
 *  - After state is initialized and restored, change in the cart could cause a change of capture_method which results in
 *      creating a new checkout session and thus a new session id
 * @returns
 */
export function CheckoutSessionProvider({ mode = CheckoutSessionProviderMode.Checkout, children }: ICheckoutSessionProviderProps) {
    const [state, setState] = useState<ICheckoutSessionState>(createEmptyState());
    const [isCheckoutSessionInitializing, setIsCheckoutSessionInitializing] = useState(true);
    const [activeAllowedPaymentTypes, setActiveAllowedPaymentTypes] = useState<PaymentTypes[]>([]);
    const {
        getAvailabilityParams,
        cart,
        property,
        initialized: isBookingEngineStateInitialized,
        isCartInitialized,
        resetBooking,
    } = appHooks.useBookingEngineStateContext();
    const [activePaymentType, setActivePaymentType] = useState<PaymentTypes>(state.payment_type);
    const propertyId = appUtils.resolvePropertyIdOrFail();
    const availabilitySearchQuery = getAvailabilityParams(AvailabilityRequestType.Check);
    const upsertCheckoutSessionMutator = useUpsertCheckoutSession(propertyId!, availabilitySearchQuery);
    const isDirectActivePaymentType = activePaymentType === PaymentTypes.Direct;
    const isOffSessionActivePaymentType = activePaymentType === PaymentTypes.OffSession;
    const hasActiveCheckoutSession =
        (isDirectActivePaymentType && !!state.external_payment_intent_id && !!state.external_payment_intent_secret) ||
        (isOffSessionActivePaymentType && !!state.external_setup_intent_id && !!state.external_setup_intent_secret);
    const areCartItemsRefundable = !!cart?.refundable && !!cart?.cancellation_within_window;
    const bookingId = state.booking_id;
    const { data: reservedBooking } = bookingHooks.useGetReservedBooking(propertyId, bookingId || "", { enabled: !!bookingId });
    const paymentIntent = reservedBooking?.payment_intent;
    const paymentSetupIntent = reservedBooking?.payment_setup_intent;

    // set state in storage on change
    // normally setCheckoutSessionState should be used over setState because it will set it in localStorage,
    // but this is to make sure it won't get out of sync, with the cost of the additional overhead
    useEffect(() => {
        if (!isCheckoutSessionInitializing) {
            storageManager.getGlobalSession().set(StorageKeys.CheckoutSessionState, state);
        }
    }, [state, isCheckoutSessionInitializing]);

    // restore state from storage initially when component is mounted
    useEffect(() => {
        if (isBookingEngineStateInitialized && isCartInitialized) {
            storageManager
                .getGlobalSession()
                .get<ICheckoutSessionState>(StorageKeys.CheckoutSessionState)
                .then((storageState) => {
                    const hasValidExternalIntentState =
                        (!!storageState?.external_payment_intent_id && !!storageState.external_payment_intent_secret) ||
                        (!!storageState?.external_setup_intent_id && !!storageState.external_setup_intent_secret);
                    const hasValidStateInStorage = !!storageState && hasValidExternalIntentState && !!storageState.session_id;

                    // For now force capture method to be AutomaticAsync because pay later is implemented with setup intent
                    // manual capturing is not needed
                    // const captureMethod = areCartItemsRefundable ? PaymentIntentCaptureMethod.Manual : DEFAULT_CAPTURE_METHOD;
                    const captureMethod = DEFAULT_CAPTURE_METHOD;

                    if (hasValidStateInStorage) {
                        // Restore valid session state from localStorage

                        setActivePaymentType(storageState.payment_type);

                        setState(storageState);

                        setIsCheckoutSessionInitializing(false);
                    } else if (mode === CheckoutSessionProviderMode.Checkout) {
                        // Create new session

                        const nextState = {
                            ...createEmptyState(),
                            capture_method: captureMethod,
                        };

                        setState(nextState);

                        setIsCheckoutSessionInitializing(false);
                    }
                });
        }
    }, [isBookingEngineStateInitialized, isCartInitialized, setActivePaymentType]);

    useEffect(() => {
        if (!isCheckoutSessionInitializing) {
            console.log("hiero1.4 - CheckoutSession state changed: ", state);
        }
    }, [state]);

    useEffect(() => {
        // const hasActiveAllowedPaymentTypes = !!activeAllowedPaymentTypes.length;

        if (property?.allowed_payment_types && Array.isArray(property?.allowed_payment_types)) {
            const nextActiveAllowedPaymentTypes = property?.allowed_payment_types || [];
            const isActivePaymentTypeAllowed = nextActiveAllowedPaymentTypes.includes(state.payment_type);

            setActiveAllowedPaymentTypes(property.allowed_payment_types);

            if (nextActiveAllowedPaymentTypes.length && !isActivePaymentTypeAllowed) {
                // clear checkout session when allowed payment types has been changed during a checkout session

                clearAllState({ payment_type: nextActiveAllowedPaymentTypes[0] }).then(() => {
                    alert(
                        "It seems that the allowed payment types have been changed during the checkout session. The state will be cleared and the page will be reloaded"
                    );

                    document.location.reload();
                });
            }
        }
    }, [property?.allowed_payment_types, state.payment_type]);

    const upsertCheckoutSession = useCallback(async (): Promise<ICheckoutSessionState> => {
        if (propertyId) {
            const nextCheckoutSessionState = await upsertCheckoutSessionMutator.mutateAsync(state);

            if (nextCheckoutSessionState.session_id) {
                setState(nextCheckoutSessionState);

                return nextCheckoutSessionState;
            } else {
                console.log("No session id in response. Something went wrong");

                // eslint-disable-next-line no-debugger
                debugger;
            }

            return nextCheckoutSessionState;
        } else {
            throw new Error("No property id set");
        }
    }, [upsertCheckoutSessionMutator, propertyId]);

    const clearCheckoutSession = useCallback(async (forcedState?: Partial<ICheckoutSessionState>): Promise<void> => {
        await storageManager.getGlobalSession().clear(StorageKeys.CheckoutSessionState);

        const nextState = { ...createEmptyState(), ...(forcedState || {}) };

        setState(nextState);

        console.log("hiero1.4 - CheckoutSession cleared | nextState: ", nextState);
    }, []);

    const clearAllState = async (forcedState?: Partial<ICheckoutSessionState>) => {
        clearCheckoutSession(forcedState);

        await Promise.all(storageManager.getAll().map((storage) => storage.clearAll())).then(() => {
            console.warn("All storages have been cleared");
        });

        resetBooking();

        setTimeout(() => {
            const nextUrl = UtilsUrl.setUrlParams(document.location.href, { p: "base", c: "search" });

            document.location.href = nextUrl;
        });
    };

    (window as any).clearAllState = clearAllState;

    const setCheckoutSessionState = useCallback(
        async (nextStateProps: Partial<ICheckoutSessionState>) => {
            const nextState = {
                ...state,
                ...nextStateProps,
            };

            setState(nextState);

            await storageManager.getGlobalSession().set(StorageKeys.CheckoutSessionState, nextState);
        },
        [setState]
    );

    console.log(
        "CheckoutSession state",
        state,
        "isCheckoutSessionInitializing",
        isCheckoutSessionInitializing,
        "isCartInitialized",
        isCartInitialized,
        "cart",
        cart
    );

    // Should be called in checkout step when cart has items
    // upsert session server side which will create a new payment intent for payment provider
    const startSession = useCallback(
        ({ payment_type, forceNew }: { payment_type?: PaymentTypes; forceNew?: boolean } = {}) => {
            if (isCheckoutSessionInitializing) {
                throw Error("Cannot start session while state is initializing");
            }

            // const nextCaptureMethod = areCartItemsRefundable ? PaymentIntentCaptureMethod.Manual : DEFAULT_CAPTURE_METHOD;
            const nextCaptureMethod = DEFAULT_CAPTURE_METHOD;
            const leadingPaymentType = payment_type || activePaymentType;
            // const isCaptureMethodChanged = nextCaptureMethod !== state.capture_method;
            const isCaptureMethodChanged = false;
            const isPaymentTypeChanged = leadingPaymentType !== state.payment_type;
            const isStatusFinished = state.status === CheckoutSessionStatus.Finished;

            const paymentType = reservedBooking?.payment_type;
            const isDirectPaymentType = paymentType === PaymentTypes.Direct;
            const paymentIntentModel = isDirectPaymentType ? reservedBooking?.payment_intent : reservedBooking?.payment_setup_intent;

            const hasSessionFinishedPayment = !!paymentIntentModel?.status && paymentUtils.isPaymentAccepted(paymentIntentModel.status);
            let nextState = state;

            // console.log("hiero1.9.1 - startSession - state", state, {
            //     isCartInitialized,
            //     isCaptureMethodChanged,
            //     hasSessionFinishedPayment,
            //     isStatusFinished,
            //     nextCaptureMethod,
            // });

            if (
                (isCartInitialized && isCaptureMethodChanged) ||
                hasSessionFinishedPayment ||
                isStatusFinished ||
                isPaymentTypeChanged ||
                forceNew
            ) {
                nextState = {
                    ...createEmptyState(),
                    capture_method: nextCaptureMethod,
                    payment_type: leadingPaymentType,
                };

                console.log(
                    "hiero1.4 - Session state cleared",
                    {
                        isCartInitialized,
                        isCaptureMethodChanged,
                        hasSessionFinishedPayment,
                        isStatusFinished,
                        isPaymentTypeChanged,
                        forceNew,
                    },
                    { leadingPaymentType, nextCaptureMethod }
                );

                setState(nextState);
            }

            upsertCheckoutSessionMutator.mutateAsync(nextState).then((nextCheckoutSessionState) => {
                if (nextCheckoutSessionState.session_id) {
                    const nextStateUpdatedOnServer = {
                        ...nextState,
                        ...nextCheckoutSessionState,
                    };

                    setState(nextStateUpdatedOnServer);

                    console.log("hiero1.1 - CheckoutSessionProvider - updated server of startSession", nextStateUpdatedOnServer);
                } else {
                    console.log("No session id in response. Something went wrong");

                    // eslint-disable-next-line no-debugger
                    debugger;
                }
            });
        },
        [upsertCheckoutSessionMutator, state, areCartItemsRefundable, isCheckoutSessionInitializing, activePaymentType]
    );

    useEffect(() => {
        if (!isCheckoutSessionInitializing && !!state.payment_type && state.payment_type !== activePaymentType) {
            // upsertCheckoutSession(state);
            setActivePaymentType(state.payment_type);

            // payment type has change, a new session should be started
            setTimeout(() => startSession({ payment_type: state.payment_type, forceNew: true }), 100);
        }
    }, [state.payment_type, isCheckoutSessionInitializing, activePaymentType, startSession]);

    const value: ICheckoutSessionContext = React.useMemo(
        () => ({
            state,
            startSession,
            isLoading: upsertCheckoutSessionMutator.isLoading,
            upsertCheckoutSession,
            clearCheckoutSession,
            hasActiveCheckoutSession,
            isCheckoutSessionInitializing,
            booking: reservedBooking,
            paymentIntent,
            paymentSetupIntent,
            setState: setCheckoutSessionState,
        }),
        [
            state,
            startSession,
            upsertCheckoutSessionMutator.isLoading,
            hasActiveCheckoutSession,
            isCheckoutSessionInitializing,
            clearCheckoutSession,
            upsertCheckoutSession,
            reservedBooking,
            paymentIntent,
            paymentSetupIntent,
            setCheckoutSessionState,
        ]
    );

    return (
        <CheckoutSessionContext.Provider value={value}>
            {typeof children === "function" ? children(value) : children}
        </CheckoutSessionContext.Provider>
    );
}
