import { Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Flex } from "@ui";
import React, { Fragment, ReactElement, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { tw } from "twind";

export interface IDialogChildrenRenderProps {
    close: () => void;
}

export interface IDialogProps {
    triggerButton?: ReactNode | ReactNode[];
    children: ReactNode | ReactNode[] | ((props: IDialogChildrenRenderProps) => ReactNode | ReactNode[] | ReactElement);
    verticalPosition?: DialogVerticalPositions;
    styleMode?: DialogStyleModes;
    heightMode?: DialogHeightModes;
    forcedOpen?: boolean;
}

export enum DialogVerticalPositions {
    Top = "top",
    Middle = "middle",
    Bottom = "bottom",
}

export enum DialogHeightModes {
    Full = "full",
    Fit = "fit",
}

export enum DialogStyleModes {
    Regular = "regular",
    Error = "error",
    Warning = "warning",
}

const popoverPanelClassNames = {
    [DialogVerticalPositions.Bottom]: {
        [DialogHeightModes.Full]: "justify-end tall:mt-32 sm:tall:mt-32",
        [DialogHeightModes.Fit]: "justify-end tall:mt-32 sm:tall:mt-32",
    },
    [DialogVerticalPositions.Middle]: {
        [DialogHeightModes.Full]: "items-center",
        [DialogHeightModes.Fit]: "items-center",
    },
    [DialogVerticalPositions.Top]: {
        [DialogHeightModes.Full]: "justify-start",
        [DialogHeightModes.Fit]: "justify-start",
    },
};

const contentOuterWrapperClassNames = {
    [DialogVerticalPositions.Bottom]: {
        [DialogHeightModes.Full]: "h-full items-end",
        [DialogHeightModes.Fit]: "h-full items-end max-h-full",
    },
    [DialogVerticalPositions.Middle]: {
        [DialogHeightModes.Full]: "h-full items-center",
        [DialogHeightModes.Fit]: "h-full items-center max-h-full",
    },
    [DialogVerticalPositions.Top]: {
        [DialogHeightModes.Full]: "items-start h-full",
        [DialogHeightModes.Fit]: "items-start h-full max-h-full",
    },
};

const contentWrapperClassNames = {
    [DialogVerticalPositions.Bottom]: {
        [DialogHeightModes.Full]: "h-full",
        [DialogHeightModes.Fit]: "h-auto max-h-full",
    },
    [DialogVerticalPositions.Middle]: {
        [DialogHeightModes.Full]: "h-auto",
        [DialogHeightModes.Fit]: "h-auto max-h-full",
    },
    [DialogVerticalPositions.Top]: {
        [DialogHeightModes.Full]: "h-full",
        [DialogHeightModes.Fit]: "h-auto max-h-full",
    },
};

const MAP_STYLE_MODE_CLASS_NAMES = {
    container: {
        [DialogStyleModes.Error]: "border-red-300 outline outline-red-300 outline-2 md:rounded-md text-red-300", // rounded-md fixes border issue which is cut off otherwise
        [DialogStyleModes.Warning]: "border-orange-300",
        [DialogStyleModes.Regular]: "",
    },
};

/**
 * @warning - please be carefull making changes to class matrix configurations
 */

export const Dialog = ({
    triggerButton,
    children,
    forcedOpen,
    styleMode = DialogStyleModes.Regular,
    verticalPosition = DialogVerticalPositions.Bottom,
    heightMode = DialogHeightModes.Fit,
}: IDialogProps) => (
    <Popover as={Fragment}>
        {({ open }) => (
            <React.Fragment>
                {triggerButton && <Popover.Button as={Fragment}>{triggerButton}</Popover.Button>}

                <Transition
                    show={open || forcedOpen}
                    as={React.Fragment}
                    enter="transition duration-500 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-500 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <Popover.Panel
                        className={tw(
                            twMerge(
                                "md:rounded-2xl rounded-xl absolute z-30 m-0 sm:m-2 left-0 right-0 top-0 bottom-0 h-auto w-auto min-h-[220px]",
                                (popoverPanelClassNames as any)[verticalPosition][heightMode]
                            )
                        )}
                        as={"div"}
                    >
                        {({ close }) => (
                            <div
                                className={tw(
                                    twMerge(
                                        "relative max-w-lg ease-in-out flex w-full max-h-full",
                                        (contentOuterWrapperClassNames as any)[verticalPosition][heightMode]
                                    )
                                )}
                            >
                                <Flex
                                    alignItems={"start"}
                                    flexDirection="col"
                                    justifyContent="start"
                                    className={tw(
                                        "md:rounded-2xl rounded-xl shadow-lg bg-white relative w-full max-h-full m-2",
                                        (contentWrapperClassNames as any)[verticalPosition][heightMode],
                                        MAP_STYLE_MODE_CLASS_NAMES.container[styleMode]
                                    )}
                                >
                                    {typeof children === "function" ? children({ close }) : children}
                                </Flex>
                            </div>
                        )}
                    </Popover.Panel>
                </Transition>
                <Transition
                    show={open || forcedOpen}
                    as={Fragment}
                    enter="transition duration-300 ease-out"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition duration-300 ease-out"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-0"
                >
                    <Popover.Overlay className="absolute inset-0 z-20 bg-black/40 sm:rounded-xl" />
                </Transition>
            </React.Fragment>
        )}
    </Popover>
);

export interface IDialogHeaderProps {
    children?: string | ReactNode | ReactNode[];
    onClose?: () => void;
}

export const DialogHeader = ({ children, onClose }: IDialogHeaderProps) => (
    <Flex alignItems="center" justifyContent="between" className={twMerge("w-full h-8 p-4", !!children ? "pt-10" : "pt-6")}>
        <div className="font-bold text-2xl">{children}</div>

        {onClose && (
            <div>
                <XMarkIcon data-testid="dialog-close-btn" width={24} className="cursor-pointer" onClick={onClose} />
            </div>
        )}
    </Flex>
);
