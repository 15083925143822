import { LanguageAndCurrencySelector, PromoCodeModal } from "@app/common";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { TooltipArrow, TooltipProvider } from "@radix-ui/react-tooltip";
import { Button, Flex, HotelchampLogo, Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import React, { Fragment, Ref, Suspense, useRef } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { useAppState } from "../hooks";
import { tw } from "../services/appElements";
import { BookingCheckWarning } from "./BookingCheckWarning";
import { NavigateBack } from "./NavigateBack";
import { Outlet } from "./router/Outlet";
import { useRouter } from "./router/RouterContext";

export interface IWidgetProps {
    style?: React.CSSProperties;
    className?: string;
    onCloseClick?: () => void;
    onTransitionEnd?: () => void;
}

const baseStyle: React.CSSProperties = {
    position: "fixed",
    right: "0px",
    bottom: "0px",
    height: "100%",
    maxHeight: "936px",
    minHeight: "250px",
};

/**
 * Widget
 */
export const Widget = React.forwardRef(
    ({ onCloseClick, onTransitionEnd, className, style, ...widgetContainerProps }: IWidgetProps, ref: Ref<HTMLDivElement>) => {
        const { t } = useTranslation(["availability", "common"]);
        const outletRef = useRef<any>(null);

        const { route } = useRouter();
        const activeStep = route.query?.c;
        const showPromoCodeButton = !["checkout", "thank-you"].includes(activeStep);

        const {
            state: { isFullView },
        } = useAppState();

        const isEntryPage = !window.location.pathname || window.location.pathname === "/";

        return (
            <div
                data-testid="widget"
                onTransitionEnd={onTransitionEnd}
                {...widgetContainerProps}
                style={{ ...baseStyle, ...(style || {}) }}
                ref={ref}
                className={tw(twMerge(className, "max-w-lg w-full sm:p-5 md:pb-14 overflow-hidden"))}
            >
                <Flex justifyContent="end" className="w-full h-full">
                    {/* @important - "relative" is required for popover backdrops to only put the backdrop on top of the widget instead of over the shadow DOM element */}
                    <div className={tw(twMerge("relative max-w-lg w-full h-full ease-in-out"))}>
                        <Flex
                            flexDirection="col"
                            justifyContent="between"
                            className={tw("sm:rounded-xl w-full h-full shadow-lg bg-white pt-7 relative")}
                        >
                            <Flex content="center" justifyContent="between" className="px-5 flex-none py-0 pb-1">
                                <Fragment>
                                    <NavigateBack />
                                    <Flex justifyContent="end" className="gap-x-4">
                                        {/* <ExpandView /> */}
                                        {showPromoCodeButton && (
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger>
                                                        <PromoCodeModal />
                                                    </TooltipTrigger>
                                                    <TooltipContent sideOffset={2}>
                                                        <p className="text-xs">Promo- & Coporatecode</p>
                                                        <TooltipArrow className="TooltipArrow" />
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        )}
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <LanguageAndCurrencySelector />
                                                </TooltipTrigger>
                                                <TooltipContent sideOffset={2}>
                                                    <p className="text-xs">Language & Currency</p>
                                                    <TooltipArrow className="TooltipArrow" />
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>

                                        <Button data-testid="ibe-close-btn" size="icon" variant="outline" onClick={onCloseClick}>
                                            <XMarkIcon width={16} className="text-gray-500 cursor-pointer" />
                                        </Button>
                                    </Flex>
                                </Fragment>
                            </Flex>
                            <BookingCheckWarning />
                            <Suspense>
                                <Outlet />
                            </Suspense>
                        </Flex>
                    </div>
                </Flex>
                <Flex justifyContent="center" alignItems="center" className="text-white text-xs p-2 invisible md:visible">
                    <p className="mr-1.5 font-normal">Powered by</p>
                    <HotelchampLogo />
                </Flex>
            </div>
        );
    }
);
