import { appHooks, appTypes, tracker } from "@app/app";
import { resolvePropertyIdOrFail } from "@app/app/utils/appUtils";
import { ControlledFormField, DateRangeSelector } from "@app/common";
import { Button, Flex } from "@ui";
import { Fragment, ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { RoomsConfiguratorSection } from "./RoomsConfiguratorSection";

export interface ISearchConfiguratorProps {
    children?: ReactElement | ReactElement[];
    onSearchClick: () => void;
}

export function SearchConfigurator({ children, onSearchClick }: ISearchConfiguratorProps) {
    const { t } = useTranslation(["availability", "common"]);
    const propertyId = resolvePropertyIdOrFail();
    const { getState, calendarAvailability, isLoading } = appHooks.useBookingEngineStateContext();
    const { control } = useFormContext<appTypes.IBookingEngineState>();
    const { bookingState, property, currency } = getState();
    const includeRates = !!property?.ui_config.show_calendar_rates;
    const { dates } = bookingState || {};
    const { fromDate, toDate } = dates || {};
    const invalidDateSelection = !fromDate || !toDate;
    const availableDates = calendarAvailability;

    const handleSearchClick = () => {
        tracker.searchQuery({ query: getState()?.bookingState });

        onSearchClick();
    };

    return (
        <Fragment>
            <div className="grow overflow-auto w-full h-auto px-5 pt-6 pb-4" data-scrollable>
                <div data-testid="calendar" className="mb-4">
                    <ControlledFormField
                        name="bookingState.dates"
                        control={control}
                        availability={availableDates}
                        component={DateRangeSelector}
                        withRates={includeRates}
                        currency={currency}
                        required={true}
                    />
                </div>

                {children}

                <RoomsConfiguratorSection propertyId={propertyId} property={property} control={control} bookingState={bookingState} />
            </div>
            <Flex
                justifyContent="center"
                className="py-3 border-t border-gray-200 px-5"
                style={{ boxShadow: "0px -2px 4px 0px rgba(101, 101, 101, 0.01), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)" }}
            >
                <Button
                    data-testid="search-btn"
                    variant="default"
                    className="w-full"
                    disabled={invalidDateSelection || isLoading}
                    onClick={handleSearchClick}
                    isLoading={isLoading}
                    loadingText=""
                >
                    {t("search.search")}
                </Button>
            </Flex>
        </Fragment>
    );
}
