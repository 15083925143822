import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Button } from "@ui";
import { useTranslation } from "react-i18next";

import { appHooks } from "..";
import { useCustomNavigate } from "../hooks";
import { useRouter } from "./router/RouterContext";

export const NavigateBack = () => {
    const { getState } = appHooks.useBookingEngineStateContext();
    const { bookingState } = getState();
    const navigate = useCustomNavigate();
    const { data, route } = useRouter();
    const isMultiRoomBooking = (bookingState?.bookings || []).length > 1;
    const activeStep = route.query.c;
    const { t } = useTranslation(["navigation"]);

    // @TODO - fix issue where data doesnt contain data of the related step but from the previous step.
    // Occurs when the CheckoutReturnPage is navigating to the CheckoutThankYouPage
    // tmp workaound to hide back button on thank you page
    const isLastStep = activeStep === "thank-you";

    const handleGoBack = () => {
        const to = isMultiRoomBooking && activeStep === "summary" ? "?p=base&c=availability" : data.back;

        navigate(to);
    };

    return data?.back && !isLastStep ? (
        <Button
            data-testid="navigate-back-btn"
            className="p-0 active:bg-white hover:bg-white hover:underline underline-offset-4 h-auto"
            icon={ChevronLeftIcon}
            iconPosition="left"
            onClick={handleGoBack}
            variant="ghost"
        >
            <span className="ml-2">Back</span>
        </Button>
    ) : null;
};
