import React, { createContext } from "react";

export interface IActiveBookingContext {
    activeBookingId: string | undefined;
    activeBookingIndex: number;
    setBookingIds: (bookingIds: string[]) => void;
    setNext: () => string | undefined;
    setPrevious: () => string | undefined;
    setSpecific: (id: string) => void;
}

export const ActiveBookingContext = createContext<IActiveBookingContext>({
    activeBookingId: undefined,
    activeBookingIndex: -1,
    setBookingIds: () => {},
    setNext: () => undefined,
    setPrevious: () => undefined,
    setSpecific: () => undefined,
});
