import { bookingTypes } from "@app/booking";
import { CheckoutStorageKeys } from "@app/checkout";
import { commonHooks } from "@app/common";
import { UtilsIdentifier } from "@hotelchamp/common";
import { merge } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMachine } from "react-robot";

import { appConfig, appUtils } from "..";
import { StorageKeys } from "../constants/StorageKeys";
import { i18next } from "../services";
import { appStateMachine } from "../services/appStateMachine";
import { storageManager } from "../services/storageManager";
import { IAppState, IIbeConfig } from "../types";
import { resolvePropertyIdOrFail } from "../utils/appUtils";
import { AppStateContext, IAppStateContext } from "./AppStateContext";

export interface IAuthLoaderProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

const win = window as any;

export function AppStateContextProvider({ children }: IAuthLoaderProviderProps) {
    const propertyId = resolvePropertyIdOrFail();
    const [stateMachineState, send] = useMachine(appStateMachine);

    const { data: translations } = commonHooks.useGetTranslations(propertyId, { enabled: !!propertyId });
    const [state, setState] = useState<IAppState>({} as IAppState);
    const [initialized, setInitialized] = useState<boolean>(false);
    const sessionStorage = storageManager.getGlobalSession();
    const currentWidgetState = stateMachineState.name;
    const isWidgetStateHidden = currentWidgetState === "hidden" || currentWidgetState === "inactive";
    let getBookingState: any = null;
    const setBookingEngineStateResolver = (fn: any) => {
        getBookingState = fn;
    };

    // Add an IBE Config updater to use from outside the App.
    const ibeConfigUpdater = (config: IIbeConfig) => setState((oldState) => ({ ...oldState, initConfig: config }));
    const setConfigRef = useRef(ibeConfigUpdater);
    setConfigRef.current = ibeConfigUpdater;

    /**
     * App Facade:
     *
     * Exposes a search function to trigger searching availability from outside the app.
     * Additional methods can be added as needed.
     *
     * Example availability search call:
     *  window.__HC__.ibe.search({
            "checkin": new Date('2024-06-25'),
            "checkout": new Date('2024-06-29'),
            "adultCount": 2,
            "childCount": 1,
            "roomCount": 1,
            "promoCode": "",
            "languageCode": "en"
        });
     */
    useEffect(() => {
        win[appConfig.globalVendorNamespace] = {
            ...(win[appConfig.globalVendorNamespace] || {}),
            [appConfig.globalAppNamespace]: {
                ...(win[appConfig.globalVendorNamespace] || {})[appConfig.globalAppNamespace],
                search: (newConfig?: IIbeConfig) => {
                    setState((oldState) => ({
                        ...oldState,
                        searchParams: !newConfig ? oldState.searchParams : "",
                    }));

                    if (newConfig) {
                        setConfigRef.current(newConfig);
                    }

                    send("SHOW");
                },
                getBooking: () => {
                    if (getBookingState) {
                        const bookingState = getBookingState();
                        let guestDetails: bookingTypes.IGuestDetails | null = null;
                        sessionStorage.get(CheckoutStorageKeys.CheckoutGuestDetailsState).then((gd) => {
                            guestDetails = gd;
                        });

                        return appUtils.ibeStateToTrackingSystemBooking(bookingState, guestDetails, state.cart || null);
                    }

                    return null;
                },
                getWidgetState: () => currentWidgetState,

                requestWidgetTransition: send,
            },
        };
    }, [setState, send, getBookingState, state.restoreableWidgetState, currentWidgetState]);

    // set state in storage on change
    useEffect(() => {
        const isEmptyState = !Object.keys(state).length;

        if (!isEmptyState) {
            storageManager.getGlobalSession().set(StorageKeys.AppState, state);
        }
    }, [state]);

    /**
     * When the ibe state value changes, sync it with storage so that
     * the state can be restored once the page reloads
     */
    useEffect(() => {
        if (!isWidgetStateHidden) {
            setState((prevAppState) => ({ ...prevAppState, restoreableWidgetState: currentWidgetState }));
        }
    }, [currentWidgetState]);

    // Restore state from storage initially when component is mount
    useEffect(() => {
        sessionStorage.get<IAppState>(StorageKeys.AppState, { sessionId: null }).then((storageAppState) => {
            const sessionId = storageAppState.sessionId || UtilsIdentifier.uuid();
            const searchParams =
                !storageAppState.initConfig && !storageAppState.searchParams ? "?p=base&c=search" : storageAppState.searchParams;

            setState((currentState) => merge(currentState, { ...storageAppState, sessionId, searchParams }));

            setInitialized(true);
        });
    }, []);

    // Load and initialize translations
    useEffect(() => {
        const trs = translations || {};

        Object.keys(trs).forEach((lang) => {
            const langTrs = trs[lang] || ({} as any);

            Object.keys(langTrs).forEach((namespace) => {
                i18next.addResourceBundle(lang, namespace, langTrs[namespace] as any);
            });
        });
    }, [translations]);

    const value: IAppStateContext = {
        state,
        setBookingEngineStateResolver,
        setState: useCallback(setState, [setState]),
        requestWidgetTransition: send,
        widgetState: stateMachineState,
        propertyId,
        initialized,
    };

    return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
}
