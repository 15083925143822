import { appHooks } from "@app/app";
import { loadStripe, type Stripe } from "@stripe/stripe-js";
import React, { useEffect, useMemo, useState } from "react";

import { IStripeContext, StripeContext } from "../context/StripeContext";

export interface IStripeProvider {
    children?: React.ReactNode | React.ReactNode[];
    stripePublishableKey: string;
}

/**
 * StripeProvider
 * @info - https://docs.stripe.com/stripe-js/react
 * @note - children components are not rendered before stripe is loaded
 * and stripe checkout session is created
 */
export const StripeProvider = ({ stripePublishableKey, children }: IStripeProvider) => {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(Promise.resolve(null));
    const [stripe, setStripe] = useState<Stripe>();
    const { getState } = appHooks.useBookingEngineStateContext();
    const { property } = getState();

    useEffect(() => {
        if (property) {
            const paymentProvider = property?.payment_provider;
            const isStripePaymentProvider = paymentProvider?.client === "stripe";
            const stripeAccountId = property?.external_payment_provider_account_id;

            // const isStripeLoadable =
            //     paymentProvider && ((process.env.NODE_ENV === "production" && stripeAccountId) || process.env.NODE_ENV !== "production");

            const isStripeLoadable = true;
            const stripeAccountIdToUse = undefined; // process.env.NODE_ENV === "production" ? stripeAccountId : undefined;

            if (isStripeLoadable) {
                const nextStripePromise = loadStripe(stripePublishableKey, {
                    stripeAccount: stripeAccountIdToUse,
                });

                nextStripePromise.then((nextStripe) => {
                    if (nextStripe) {
                        setStripe(nextStripe);
                    }
                });

                console.log("Stripe OK");

                setStripePromise(nextStripePromise);
            } else if (!paymentProvider) {
                throw new Error("No payment provider configured");
            } else if (process.env.NODE_ENV === "production") {
                if (paymentProvider && !isStripePaymentProvider) {
                    throw new Error("Is not a Stripe payment provider");
                } else if (isStripePaymentProvider) {
                    throw new Error("No stripe account id configured");
                } else if (property) {
                    throw new Error("No Stripe payment provider configured");
                }
            }
        }
    }, [property]);

    const value: IStripeContext = useMemo(() => ({ stripe, stripePromise, isInitialized: !!stripePromise }), [stripe, stripePromise]);

    return <StripeContext.Provider value={value}>{children}</StripeContext.Provider>;
};
