import { appHooks } from "@app/app";
import { BodyScrollPreventer } from "@app/common";
import { convertHexToHsl } from "@app/common/utils/colorUtils";
import React, { useEffect, useRef, useState, useTransition } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { twMerge } from "tailwind-merge";
import { tw } from "twind";

import { ErrorBoundaryProvider } from "../context/ErrorBoundaryProvider";
import { useBookingEngineStateContext } from "../hooks";
import { ErrorFallback } from "./ErrorFallback";
import { Widget } from "./Widget";
import { WidgetFloatingTrigger } from "./WidgetFloatingTrigger";

const backdropStyle: React.CSSProperties = {
    zIndex: "2147483646",
};

export const AppWidgetsManager = () => {
    const [, startTransition] = useTransition();
    const { state: appState, setState: setAppState, initialized, requestWidgetTransition, widgetState } = appHooks.useAppState();
    const [initialRestorableStateValue, setInitialRestorableStateValue] = useState<string>();
    const { isLoading: isBookingEngineStateLoading, getState } = useBookingEngineStateContext();
    const triggerButtonRef = useRef<HTMLDivElement>(null);
    const widgetRef = useRef<HTMLDivElement>(null);
    const currentState = widgetState.name;
    const isStateVisible = currentState === "visible";
    const isStateHidden = currentState === "hidden" || currentState === "inactive";
    const isPrevStateHidden = currentState === "visible" || currentState === "inactive";

    const { property } = getState();
    const theme = property?.theme;
    const primaryColor = theme?.colors.primary;

    /**
     * Try to resolve previous ibe state from storage and set the value as the
     * value to restore to.
     * When no value could be found in storage, fallback to "visible" state.
     */
    useEffect(() => {
        if (initialized) {
            const storedWidgetState = appState.restoreableWidgetState || "hidden";

            setInitialRestorableStateValue(storedWidgetState);
        }
    }, [initialized]);

    useEffect(() => {
        if (primaryColor) {
            const color = convertHexToHsl(primaryColor);

            if (color !== null) {
                handleChangeColor(`${color.h}, ${color.s}, ${color.l}`);
            }
        }
    }, [primaryColor]);

    /**
     * This effect is responsible to restore the state of the widget based on the
     * restorable state value.
     * @note - when no restorableState value was found in storage, the default value "visible" is being used.
     *         But this is handled within the hook that is responsible to obtain the value from storage...
     */
    useEffect(() => {
        if (initialRestorableStateValue) {
            let event = "";

            if (initialRestorableStateValue === "visible") {
                event = "SHOW";
            } else if (initialRestorableStateValue === "minimised") {
                event = "MINIMISE";
            }

            if (event) {
                setTimeout(() => transitionAppState(event), isBookingEngineStateLoading ? 1200 : 1200);
            }
        }
    }, [initialRestorableStateValue]);

    /**
     * Fix for scrolling issue in Radix UI Select component when used
     * within shadow DOM
     * @source - https://github.com/radix-ui/primitives/issues/1980
     */
    useEffect(() => {
        const handleWheel = (e: WheelEvent) => {
            if ((e.target as Element).closest("[data-scrollable]")) return;
            e.stopPropagation();
        };

        const handleTouchMove = (e: TouchEvent) => {
            if ((e.target as Element).closest("[data-scrollable]")) return;
            e.stopPropagation();
        };

        document.addEventListener("wheel", handleWheel, true);
        document.addEventListener("touchmove", handleTouchMove, true);

        return () => {
            document.removeEventListener("wheel", handleWheel, true);
            document.removeEventListener("touchmove", handleTouchMove, true);
        };
    }, []);

    const handleWidgetCloseClick = () => {
        transitionAppState("MINIMISE");
    };

    const handleTriggerClick = () => {
        transitionAppState("SHOW");
    };

    const handleTransitionEnd = () => {
        // send("DONE");
    };

    const transitionAppState = (event: string) => {
        startTransition(() => {
            requestWidgetTransition(event);
        });
    };

    const handleChangeColor = (color: string) => {
        const colorArray = color.split(",");
        colorArray[2] = " 92%";

        window["hc-ibe-root"].style.setProperty("--hc-primary", color);
        window["hc-ibe-root"].style.setProperty("--hc-secondary", colorArray.join(","));
        window["hc-ibe-root"].style.setProperty("--hc-secondary-foreground", color);
    };

    return (
        <div className={tw("overflow-hidden text-foreground")}>
            <div
                className={tw(
                    twMerge(
                        "ease-in fixed top-0 right-0 w-full h-full",
                        currentState === "visible" ? "bg-black/40 pointer-events-auto" : "pointer-events-none",
                        isStateHidden ? "duration-0 transition-none" : "duration-500 transition-all"
                    )
                )}
                style={backdropStyle}
            ></div>

            <BodyScrollPreventer open={isStateVisible} />

            <ErrorBoundaryProvider>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Widget
                        onTransitionEnd={handleTransitionEnd}
                        onCloseClick={handleWidgetCloseClick}
                        ref={widgetRef}
                        className={tw(
                            twMerge(
                                "ease-in-out",
                                currentState !== "visible"
                                    ? `translate-x-[${widgetRef.current?.offsetWidth || 800}px]`
                                    : "translate-x-[0px]",
                                currentState !== "visible" ? `right-[${-(widgetRef.current?.offsetWidth || 800)}px]` : "right-[0px]",
                                currentState === "minimised" ? "" : "delay-500",
                                isStateHidden ? "duration-0 transition-none" : "duration-500 transition-all"
                            )
                        )}
                        style={{
                            visibility: isStateHidden ? "hidden" : "visible",
                            zIndex: "2147483647",
                        }}
                    />
                </ErrorBoundary>
            </ErrorBoundaryProvider>
            <WidgetFloatingTrigger
                onClick={handleTriggerClick}
                ref={triggerButtonRef}
                className={tw(
                    twMerge(
                        "drop-shadow-md ease-in-out",
                        currentState !== "minimised"
                            ? `translate-x-[${triggerButtonRef.current?.offsetWidth || 0}px]`
                            : "translate-x-[0px]",
                        currentState === "visible" ? "" : "delay-500",
                        isStateHidden || (isPrevStateHidden && initialRestorableStateValue !== "minimised")
                            ? "duration-0 transition-none"
                            : "duration-500 transition-all"
                    )
                )}
                style={{
                    visibility: isStateHidden || (isPrevStateHidden && initialRestorableStateValue !== "minimised") ? "hidden" : "visible",
                    // zIndex: "2147483648",
                }}
            />
        </div>
    );
};
