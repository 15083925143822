import { FullScreenIcon, LoadingDots, PriceableItemPrice } from "@app/common";
import { Flex } from "@app/componentLibrary";
import React, { Ref } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { useBookingEngineStateContext } from "../hooks";

export interface IWidgetFloatingTriggerProps {
    style?: React.CSSProperties;
    className?: string;
    offset?: {
        x: number;
        y: number;
    };
    onClick: () => void;
}

const baseContainerStyle: React.CSSProperties = {
    position: "fixed",
    bottom: "0px",
    right: "0px",
};

const baseWidgetStyle: React.CSSProperties = {
    // backgroundColor: "lightgrey",
};

const defaultOffset = {
    x: 20,
    y: 20,
};

const EMPTY_BOOKING_STATE = {};

export const WidgetFloatingTrigger = React.forwardRef((props: IWidgetFloatingTriggerProps, ref: Ref<HTMLDivElement>) => {
    const { getState, getTotalPrice, isLoading } = useBookingEngineStateContext();
    const { t } = useTranslation("common");
    const { bookingState, activeStep } = getState();
    const { bookings } = bookingState || EMPTY_BOOKING_STATE;
    const firstBooking = bookings?.[0];
    const firstRoomImage = firstBooking ? firstBooking.room?.images[0].path || null : null;
    const totalDiscountedPrice = getTotalPrice({ discounted: true });
    const totalOriginalPrice = getTotalPrice({ discounted: false });
    const showTotalPrice = (activeStep === "summary" || activeStep === "checkout") && !isLoading;
    const buttonTitle = activeStep === "thank-you" ? t("viewYourBooking") : t("continueYourBooking");

    return (
        <div
            data-testid="floating-trigger-btn"
            {...props}
            style={{ ...baseContainerStyle, ...(props.style || {}) }}
            className={twMerge(
                "w-fit",
                props.className,
                `px-[${props.offset?.x || defaultOffset.x}px]`,
                `py-[${props.offset?.y || defaultOffset.y}px]`
            )}
            ref={ref}
            onClick={props.onClick}
        >
            <Flex
                style={baseWidgetStyle}
                justifyContent="start"
                className={twMerge(
                    "rounded-xl hover:drop-shadow-xl overflow-hidden bg-primary hover:bg-primary/90 text-white cursor-pointer transition-colors"
                )}
            >
                {firstRoomImage && (
                    <div className="overflow-hidden">
                        <img
                            data-testid={`room-${firstBooking.id}-img`}
                            src={firstRoomImage}
                            alt="room"
                            className="w-24 h-[54px] rounded-l-lg object-cover"
                        />
                    </div>
                )}
                <Flex flexDirection="col" justifyContent="start" alignItems="start" className="my-1.5 mx-2 gap-1">
                    <Flex className="gap-x-2">
                        <div className="text-sm font-medium leading-[17px]">{buttonTitle}</div>
                        <FullScreenIcon className="w-4 h-4 fill-white" />
                    </Flex>
                    {isLoading && <LoadingDots className="fill-white" />}
                    {showTotalPrice ? (
                        <div>
                            <PriceableItemPrice
                                className="font-bold"
                                moneyClassName="text-white text-xs"
                                outdatedMoneyClassName="text-white/60 text-xs font-normal"
                                outdatedMoneyStrikeThroughClassName="border-t-white/50 border-b-white/50"
                                item={totalOriginalPrice}
                                discountedPrice={totalDiscountedPrice}
                            />
                        </div>
                    ) : null}
                </Flex>
            </Flex>
        </div>
    );
});
