import { Storage, StorageManager as CommonStorageManager } from "@hotelchamp/common";

import { StorageTypes } from "../constants/StorageTypes";

/**
 * StorageManager
 * Extends common/StorageManager to provide an
 * application specific API.
 */
export class StorageManager extends CommonStorageManager {
    /**
     * registerByType
     * Helper to easily register Storage by StorageTypes
     */
    public registerByType(type: StorageTypes, storage: Storage, key?: string) {
        this.register(this.getInternalKey(type, key), storage);
    }

    /**
     * getByType
     * Get Storage by StorageTypes
     */
    public getByType(type: StorageTypes, key?: string) {
        return this.get(this.getInternalKey(type, key));
    }

    /**
     * getGlobal
     * Get global Storage which can be used application wide
     */
    public getGlobal() {
        return this.get(`${StorageTypes.Global}`);
    }

    /**
     * getGlobalSession
     * Get global session Storage which can be used application wide
     */
    public getGlobalSession() {
        return this.get(`${StorageTypes.GlobalSession}`);
    }

    public removeByType(type: StorageTypes, key?: string) {
        return this.remove(this.getInternalKey(type, key));
    }

    /**
     * getInternalKey
     * Helper method to concat StorageTypes and optional key
     */
    private getInternalKey(type: StorageTypes, key?: string): string {
        return `${type}` + (key ? `.${key}` : "");
    }
}
